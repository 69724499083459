.homepage {
    /* BANNER + HEADER */
    .banner {
        background: url("../../assets/home-banner.webp");
        position: relative;
        background-size: cover;

        .bg-overlay {
            background-color: rgba($color: #000000, $alpha: 0.6);
            height: 86.5vh;

            .container {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .details {
                h1 {
                    font-size: 55px;
                    font-weight: 700;
                    color: #fff;
                    text-align: center;
                    margin-bottom: 0px;
                    line-height: 75px;

                    span {
                        font-family: "Inter Tight", sans-serif;
                        font-weight: 900;
                        font-style: italic;
                    }
                }

                .subhead {
                    font-size: 22px;
                    font-weight: 500;
                    color: rgba($color: #fff, $alpha: 0.8);
                    line-height: 35px;
                }

                h5 {
                    margin-top: 15px;
                    font-weight: 500;
                    line-height: 25px;
                    font-size: 18px;
                    text-align: center;
                    color: #fff;
                }

                .banner-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    margin-top: 40px;

                    .white-border-btn {
                        border: 2px solid #fff;
                        background-color: transparent;
                        color: #fff;
                        padding: 8px 20px;
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .white-border-btn:hover {
                        background-color: #fff;
                        color: #323232;
                    }
                }
            }

            .listing-search {
                background-color: rgba($color: #fff, $alpha: 0.8);
                border-radius: 5px;
                padding: 10px;
                margin-top: 40px;
                display: flex;
                align-items: center;
                gap: 15px;

                .banner-control {
                    width: 100%;
                    background-color: #fff;
                    border: 0px solid transparent;
                    padding: 10px 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                }

                .banner-control::placeholder {
                    color: #6f6f6f;
                    font-size: 14px;
                }

                .search-button {
                    white-space: nowrap;
                    background-color: #4258a3;
                    border-color: #4258a3;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 8px 16px;
                }
            }
        }
    }

    /* ABOUT ME */
    .about {
        padding: 70px 0px;

        img {
            width: 100%;
            height: 600px;
            object-fit: cover;
            border-radius: 10px;
        }

        .small-image {
            height: 300px;
            object-fit: cover;
        }

        h2 {
            font-family: "Proxima-Extrabold", sans-serif;
            font-size: 40px;
            font-weight: 800;
            color: #000;
            margin-bottom: 0px;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            color: #696969;
            margin-top: 20px;
        }
    }

    /* LATEST PROPERTIES */
    .featured-listings {
        padding: 70px 0px;
        padding-top: 0px;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                font-size: 16px;
                font-weight: 700;
                color: #4258a3;
                margin-bottom: 10px;
            }

            h2 {
                font-family: "Proxima-Extrabold", sans-serif;
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            .listings-link {
                display: block;
                text-decoration: none;
                color: #000;
                font-size: 18px;
                font-weight: 600;
            }

            .listings-link:hover {
                color: #4258a3;
            }
        }

        .property-box {
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            cursor: pointer;

            .property-image {
                position: relative;

                .thumbnail-img {
                    width: 100%;
                    height: 25vh;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    object-fit: cover;
                }

                .loading-skull {
                    height: 25vh;
                    width: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            .thumbnail-details {
                background-color: #fff;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 15px 20px;

                .heading {
                    font-size: 18px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 0px;
                }

                .sub-heading {
                    font-size: 15px;
                    font-weight: 400;
                    color: grey;
                    margin-bottom: 0px;
                    margin-top: 5px;
                }

                .house-details {
                    margin-top: 15px;

                    .area {
                        font-size: 15px;
                        color: #000;
                        font-weight: 500;
                        margin-bottom: 0px;
                    }

                    .price {
                        font-size: 18px;
                        color: #000;
                        font-weight: 500;
                        margin-bottom: 0px;
                        margin-top: 5px;
                    }
                }

                .visit-property-btn {
                    width: 100%;
                    padding: 8px 16px;
                    border-color: #4159a3;
                    background-color: #4159a3;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    border-radius: 5px;
                    margin-top: 10px;
                }
            }
        }

        .swiper {
            padding: 0px 12px;

            .swiper-button-next {
                height: 40px !important;
                width: 40px !important;
                top: 55%;
            }

            .swiper-button-prev {
                height: 40px !important;
                width: 40px !important;
                top: 55%;
            }

            .swiper-button-next:after,
            .swiper-button-prev:after {
                background-color: #e5e5e5;
                border: 2px solid #e5e5e5;
                font-size: 12px !important;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                font-weight: 700;
                color: #000;
            }
        }
    }

    /* SERVICES */
    .services {
        padding: 70px 0px;
        background: linear-gradient(rgba($color: #4258a3, $alpha: 0.2), #fff, #fff);

        .heading {
            h5 {
                font-size: 16px;
                font-weight: 700;
                color: #4258a3;
                margin-bottom: 10px;
            }

            h2 {
                font-family: "Proxima-Extrabold", sans-serif;
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }
        }

        .services-box {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 1px 40px rgba($color: #000000, $alpha: 0.1);
            border: 2px solid #fff;
            padding: 30px 50px;
            text-align: center;

            img {
                width: 70px;
                height: auto;
            }

            h4 {
                font-family: "Proxima-Bold", sans-serif;
                font-weight: 700;
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                text-transform: uppercase;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                color: #6f6f6f;
                margin-bottom: 0px;
            }
        }

        .services-box:hover {
            border: 2px solid #4258a3;
        }
    }

    /* TESTIMONIALS */
    .testimonials {
        padding: 70px 0px;

        .heading {
            h2 {
                font-family: "Proxima-Extrabold", sans-serif;
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            h5 {
                font-size: 18px;
                margin-top: 10px;
                font-weight: 500;
                color: #6f6f6f;
                margin-bottom: 0px;
            }
        }

        .testimonial-box {
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            padding: 25px;
            width: 100%;

            h5 {
                font-size: 18px;
                font-weight: 700;
                color: #000;
                margin-bottom: 0px;
            }

            p {
                font-size: 14px;
                margin-top: 15px;
                font-weight: 400;
                color: #6f6f6f;
                margin-bottom: 0px;
            }

            .testimonial-profile {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 25px;
                border-top: 1px solid #e5e5e5;
                padding-top: 15px;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .profile-details {
                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        color: #6f6f6f;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    /* INSTAGRAM WALL */
    .instagram-wall {
        padding: 60px 0px;
        background-color: rgba($color: #4258a3, $alpha: 0.2);
        margin: 0px 50px;
        border-radius: 20px;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            .instagram-link {
                display: block;
                text-decoration: none;
                color: #000;
                font-size: 25px;
                font-weight: 600;
            }

            .instagram-link:hover {
                color: #4258a3;
            }
        }

        .instagram-box {
            border-radius: 5px;
            position: relative;
            transition: opacity 0.3s ease-in-out;

            video {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 5px;
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 5px;
            }

            .bg-overlay {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($color: #000000, $alpha: 0.3);
                width: 100%;
                height: 400px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
            }
        }

        .instagram-box:hover {
            .bg-overlay {
                opacity: 1;
            }
        }
    }

    .instagram-wall-amberwood {
        padding: 60px 0px;
        background-color: #fff;
        margin: 0px 50px;
        border-radius: 20px;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            .instagram-link {
                display: block;
                text-decoration: none;
                color: #000;
                font-size: 25px;
                font-weight: 600;
            }

            .instagram-link:hover {
                color: #4258a3;
            }
        }

        .instagram-box {
            border-radius: 5px;
            position: relative;
            transition: opacity 0.3s ease-in-out;

            video {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 5px;
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 5px;
            }

            .bg-overlay {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($color: #000000, $alpha: 0.3);
                width: 100%;
                height: 400px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
            }
        }

        .instagram-box:hover {
            .bg-overlay {
                opacity: 1;
            }
        }
    }

    /* GET IN TOUCH */
    .get-in-touch {
        background-color: #edf7fa;
        margin: 0px 50px;
        border-radius: 20px;
        padding: 40px;

        .contact-form {
            background-color: #fff;
            border-radius: 10px;
            padding: 25px;
            box-shadow: 0px 5px 30px 0px rgba($color: #000000, $alpha: 0.1);

            h4 {
                font-size: 20px;
                font-weight: 700;
                color: #000;
                margin-bottom: 0px;
            }

            .submit-btn {
                width: 100%;
                margin-top: 15px;
                background-color: #4258a3;
                border-color: #4258a3;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .heading {
            h5 {
                font-size: 16px;
                font-weight: 700;
                color: #4258a3;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }
        }

        .description {
            margin-top: 20px;

            p {
                margin-bottom: 0px;
                font-size: 20px;
                color: #696969;
                font-weight: 400;
            }
        }
    }

    /* BLOGS */
    .blogs {
        padding: 70px 0px;

        .heading {
            h5 {
                font-size: 16px;
                font-weight: 700;
                color: #4258a3;
                margin-bottom: 10px;
            }

            h2 {
                font-family: "Proxima-Extrabold", sans-serif;
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }
        }

        .blog-box {
            position: relative;

            img {
                width: 100%;
                height: 400px;
                border-radius: 10px;
                object-fit: cover;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: #000000, $alpha: 0.6);
                border-radius: 10px;
                display: flex;
                align-items: flex-end;

                .details {
                    padding: 25px;

                    h5 {
                        font-size: 16px;
                        color: #fff;
                        font-weight: 600;
                        margin-bottom: 0px;
                        line-height: 22px;
                    }

                    .route-link {
                        display: block;
                        margin-top: 10px;
                        color: #fff;
                        text-decoration: none;
                        font-size: 13px;
                        font-weight: 400;
                    }
                }
            }
        }

        .blog-box:hover {
            .overlay {
                .details {
                    h5 {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    /* COUNTERS */
    .counters-section {
        padding: 0px 0px 70px 0px;

        .counter-box {
            width: 100%;
            border-radius: 5px;
            background-color: rgba(245, 245, 245, 0.5);
            border: 1px solid #e5e5e5;
            padding: 20px;

            .border-right {
                border-right: 2px solid #e5e5e5;
            }

            .box {
                text-align: center;

                .value {
                    font-family: "Proxima-Bold", sans-serif;
                    font-size: 48px;
                    font-weight: 700;
                    color: #000;
                    text-align: center;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 16px;
                    color: #000;
                    font-weight: 500;
                    margin-bottom: 0px;
                    text-align: center;
                }
            }
        }
    }
}
