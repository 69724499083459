.header.dark {
    background-color: rgba($color: #fff, $alpha: 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .top-header {
        border-bottom: 1px solid #e5e5e5;

        .header-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-section {
                display: flex;
                align-items: center;

                .social-media-box {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid rgba($color: #cecece, $alpha: 0.3);
                    border-right: 1px solid rgba($color: #cecece, $alpha: 0.3);
                    padding: 8px 12px;
                    text-decoration: none;
                    color: #323232;

                    p {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0px;
                        margin-left: 10px;
                    }

                    svg {
                        fill: #323232;
                    }
                }

                .social-media-box:first-child {
                    border-left: 1px solid rgba($color: #cecece, $alpha: 0.3);
                }

                .social-media-box:hover {
                    background-color: #323232;

                    svg {
                        fill: #fff;
                    }

                    p {
                        color: #fff;
                    }
                }
            }

            .right-section {
                display: flex;
                align-items: center;

                .social-media-box {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid #e5e5e5;
                    border-right: 1px solid #e5e5e5;
                    padding: 8px 12px;
                    text-decoration: none;
                    color: #000;

                    svg {
                        fill: #000;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0px;
                        margin-left: 10px;
                    }
                }

                .phone-whatsapp {
                    display: flex;
                    justify-content: center;
                }

                .social-media-box:last-child {
                    padding-right: 0px;
                    border-right: 0px solid transparent;
                }
            }
        }
    }

    .header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px;
        border-bottom: 1px solid #e5e5e5;

        .header-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo-box {
                background-color: #fff;
                border-radius: 5px;
                display: flex;
                align-items: center;

                .company-logo {
                    width: 170px;
                    height: auto;
                    object-fit: cover;
                }

                .agent-logo {
                    width: 100px;
                    height: auto;
                    border-left: 2px solid #e5e5e5;
                    padding-left: 20px;
                }
            }
        }

        .navigation-links {
            .logo-box {
                img {
                    width: 180px;
                    height: auto;
                }
            }

            .navbar {
                padding: 0px;
            }

            .main-menu {
                .dropdown-toggle {
                    font-family: "Proxima-Semibold", sans-serif;
                    font-size: 14px;
                    color: #000;
                    font-weight: 600;
                    padding: 0px 25px;
                    text-transform: uppercase;
                }

                .dropdown-menu {
                    padding: 10px 0px;

                    .dropdown-link {
                        font-size: 14px;
                        color: #000;
                        font-weight: 600;
                        padding: 5px 20px;
                        text-transform: uppercase;
                        border-radius: 5px;
                    }

                    .dropdown-link:active {
                        color: white;
                        border-radius: 0px;
                    }
                }

                .menu-link {
                    font-size: 14px;
                    color: #000;
                    font-weight: 600;
                    padding: 0px 30px;
                    text-transform: uppercase;
                }

                .menu-link:last-child {
                    padding-right: 0px;
                }

                .menu-link:first-child {
                    padding-left: 0px;
                }
            }
        }
    }
}
