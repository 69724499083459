.property-type {
    /* PAGE BANNER */
    .page-banner {
        position: relative;

        video {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }

        .overlay-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 300px;
            background-color: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                font-family: "Proxima-Extrabold", sans-serif;
                font-size: 50px;
                font-weight: 800;
                color: #fff;
                margin-bottom: 0px;
                text-align: center;
            }

            h2 {
                font-family: "Proxima-Semibold", sans-serif;
                font-size: 20px;
                font-weight: 500;
                font-style: italic;
                margin-top: 15px;
                margin-bottom: 0px;
                color: #fff;
                text-align: center;
                line-height: 30px;
            }
        }
    }

    /* PAGE DETAILS */
    .page-details {
        padding: 35px 0px 35px 0px;

        .col-flex {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .counters {
            width: 100%;
            background-color: rgba(245, 245, 245, 0.5);
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 20px;

            .border-right {
                border-right: 2px solid #e5e5e5;
            }

            .box {
                text-align: center;

                .value {
                    font-size: 30px;
                    font-weight: 700;
                    color: #000;
                    text-align: center;
                    margin-bottom: 0px;
                    line-height: 35px;
                }

                p {
                    font-size: 15px;
                    color: #000;
                    font-weight: 500;
                    margin-bottom: 0px;
                    text-align: center;
                    line-height: 28px;
                }
            }
        }

        .list-boxes {
            .box {
                width: 100%;
                border-radius: 5px;
                border: 1px solid rgba(66, 88, 163, 0.5);
                background-color: rgba($color: #4258a3, $alpha: 0.1);
                padding: 20px;

                h5 {
                    font-family: "Proxima-Semibold", sans-serif;
                    font-size: 20px;
                    color: #000;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 0px;
                    color: #323232;
                    line-height: 26px;
                }
            }
        }

        h2 {
            font-family: "Proxima-Bold", sans-serif;
            font-size: 38px;
            font-weight: 800;
            margin-bottom: 0px;
            color: #4258a3;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 20px;
        }

        p:last-child {
            margin-bottom: 0px;
        }
    }

    /* PROPERTY LISTING */
    .property-listing {
        padding: 35px 0px;

        h2 {
            font-family: "Proxima-Bold", sans-serif;
            font-size: 38px;
            font-weight: 800;
            margin-bottom: 0px;
            color: #4258a3;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #323232;
            line-height: 26px;
            margin-top: 10px;
        }

        .property-box {
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            cursor: pointer;
            margin-top: 20px;

            .property-image {
                position: relative;

                .thumbnail-img {
                    width: 100%;
                    height: 25vh;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    object-fit: cover;
                }

                .loading-skull {
                    height: 25vh;
                    width: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            .thumbnail-details {
                background-color: #fff;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 15px 20px;

                .heading {
                    font-size: 20px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 0px;
                }

                .sub-heading {
                    font-size: 15px;
                    font-weight: 400;
                    color: grey;
                    margin-bottom: 0px;
                    margin-top: 5px;
                }

                .house-details {
                    margin-top: 15px;

                    .area {
                        font-size: 15px;
                        color: #000;
                        font-weight: 500;
                        margin-bottom: 0px;
                    }

                    .price {
                        font-size: 15px;
                        color: #000;
                        font-weight: 500;
                        margin-bottom: 0px;
                        margin-top: 5px;
                    }
                }

                .visit-property-btn {
                    width: 100%;
                    padding: 8px 16px;
                    border-color: #4159a3;
                    background-color: #4159a3;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    border-radius: 5px;
                    margin-top: 10px;
                }
            }
        }
    }

    /* PAGE CONTENT */
    .page-content {
        padding: 0px 0px 60px 0px;

        .content {
            h2 {
                font-size: 30px;
                font-weight: 800;
                margin-bottom: 15px;
                color: #4258a3;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                margin-bottom: 20px;
            }

            ul {
                li {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }

            p.font-bold {
                font-weight: 700;
            }

            .list-boxes {
                .box {
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #e5e5e5;
                    background-color: rgba($color: #4258a3, $alpha: 0.1);
                    padding: 10px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    p {
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        color: #323232;
                        line-height: 22px;
                        text-align: center;
                    }
                }
            }
        }

        .content.margin {
            margin-top: 50px;
        }

        .content.with-image {
            margin-top: 50px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                object-fit: cover;
            }
        }
    }
}
