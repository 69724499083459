@media (max-width: 575px) {
    .container {
        padding: 0vh 2vh !important;
    }

    .homepage .blogs {
        padding: 30px 0px !important;
    }

    .homepage .blogs .blog-box {
        margin-top: 15px;
    }

    .homepage .blogs .heading h2 {
        font-size: 25px !important;
    }

    .homepage .get-in-touch {
        margin: 0px 10px !important;
        padding: 15px !important;

        .heading {
            margin-top: 20px !important;
        }
    }

    .homepage .get-in-touch .heading h2 {
        font-size: 25px !important;
    }

    .homepage .get-in-touch .description p {
        font-size: 16px !important;
    }

    .homepage .services .heading h2 {
        font-size: 25px !important;
    }

    .homepage .services {
        padding: 30px 0px !important;
    }

    .homepage .latest-properties .property-box {
        margin-bottom: 15px !important;
    }

    .homepage .latest-properties {
        padding: 30px 0px !important;
    }

    .homepage .latest-properties .heading h2 {
        font-size: 25px !important;
    }

    .homepage .about-me {
        padding: 30px 0px !important;

        h2 {
            margin-top: 20px;
            font-size: 25px !important;
        }

        p {
            font-size: 15px;
        }
    }

    .homepage .banner .header .top-header .header-links {
        display: block !important;
    }

    .homepage .banner .header .top-header .header-links .left-section {
        width: 100%;
        justify-content: center;
    }

    .homepage .banner .header .top-header .header-links .left-section .social-media-box {
        border: 0px solid transparent !important;
    }

    .homepage .banner .header .top-header .header-links .right-section .social-media-box {
        border: 0px solid transparent !important;
        justify-content: center !important;
    }

    .header.dark .top-header .header-links {
        display: block !important;
    }

    .header.dark .top-header .header-links .left-section {
        width: 100%;
        justify-content: center;
    }

    .header.dark .top-header .header-links .left-section .social-media-box {
        border: 0px solid transparent !important;
    }

    .header.dark .top-header .header-links .right-section .social-media-box {
        border: 0px solid transparent !important;
        justify-content: center !important;
    }

    .homepage .banner .bg-overlay .details h1 {
        font-size: 35px !important;
        line-height: 45px;
    }

    .homepage .banner .bg-overlay .details .banner-buttons {
        display: block;
    }

    .homepage .banner .bg-overlay .details h5 {
        font-size: 16px !important;
        line-height: 22px !important;
    }

    .homepage .banner .bg-overlay .listing-search {
        display: block !important;
    }

    .homepage .banner .bg-overlay .listing-search .search-button {
        margin-top: 10px !important;
        width: 100% !important;
    }

    .homepage .banner .bg-overlay {
        padding: 80px 0px !important;
    }

    .navbar-toggler {
        background-color: #fff;
    }

    .header .header-wrap .header-links {
        border-bottom: 0px solid transparent;
    }

    .homepage .banner .bg-overlay .details .banner-buttons .white-border-btn {
        width: 100%;
        font-size: 14px !important;
        margin-top: 10px;
    }

    .homepage .about h2 {
        font-size: 25px !important;
        margin-top: 10px;
    }

    .homepage .about p {
        font-size: 16px !important;
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }

    .homepage .featured-listings .heading h2 {
        font-size: 25px !important;
    }

    .featured-swiper {
        padding-left: 10px;
    }

    .homepage .testimonials {
        padding-top: 50px;
    }

    .homepage .testimonials .heading h2 {
        font-size: 25px !important;
    }

    .homepage .testimonials .testimonial-box h5 {
        font-size: 18px !important;
    }

    .homepage .testimonials .testimonial-box .profile-details h5 {
        font-size: 16px !important;
    }

    .homepage .instagram-wall {
        padding: 30px 0px !important;
        margin: 0px 10px !important;
    }

    .homepage .instagram-wall .heading {
        display: block !important;
    }

    .homepage .instagram-wall .heading h2 {
        font-size: 25px !important;
    }

    .homepage .instagram-wall .heading .instagram-link {
        font-size: 20px !important;
        margin-top: 10px;
    }

    .homepage .blogs .blog-box .overlay .details h5 {
        font-size: 18px !important;
    }

    .footer .footer-main .agent-details {
        display: block !important;
    }

    .footer .footer-main .agent-details .details-box {
        margin-bottom: 15px;
    }

    .footer .footer-main .agent-name {
        font-size: 20px !important;
    }

    .footer .footer-main .agent-details .details-box h5 {
        font-size: 16px !important;
    }

    .footer .footer-main .agent-details .details-box p {
        font-size: 15px !important;
    }

    .footer .footer-main .agent-details .details-box a {
        font-size: 15px !important;
    }

    .footer .footer-main .footer-small-info {
        font-size: 12px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .footer .copyright-main {
        display: block !important;
    }

    .footer .copyright-main h5 {
        text-align: center !important;
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }

    .footer .copyright-main .links {
        justify-content: space-between;
    }

    .footer .copyright-main .links a {
        padding: 0px 5px !important;
        font-size: 11px !important;
    }

    .buyer-overview .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .buyer-overview .page-content .content h2 {
        font-size: 25px !important;
    }

    .seller-overview .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .seller-overview .page-content .content h2 {
        font-size: 25px !important;
    }

    .contact-us .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .contact-us .details iframe {
        margin-top: 10px;
        height: 400px !important;
    }

    .active-listings-page-2 .property-listings .section-header .switcher-buttons {
        justify-content: center !important;
        margin-top: 10px;
    }

    .active-listings-page-2 .property-listings .price-filters .react-select.sort-control .custom-select__control {
        margin-top: 10px;
    }

    .active-listings-page-2 .property-listings .price-filters .filters-btn {
        margin-top: 10px;
        height: auto !important;
    }

    .listing-list .listing-list-body .property-images .swiper {
        width: 100px !important;
    }

    .listing-list .listing-list-body .property-images .property-img {
        width: 100px !important;
        height: 100px !important;
    }

    .listing-list .listing-list-body .property-details .top-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .top-section h5 {
        font-size: 16px !important;
        margin-bottom: 5px !important;
    }

    .listing-list .listing-list-body .property-details .top-section .badge {
        padding: 5px 10px !important;
    }

    .listing-list .listing-list-body .property-details .top-section .images-number {
        padding: 2px 10px !important;
    }

    .listing-list .listing-list-body .property-details .middle-section h5 {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .listing-list .listing-list-body .property-details .middle-section p {
        margin-top: 0px !important;
    }

    .listing-list .listing-list-body .property-details .middle-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .middle-section p .mls-no {
        font-size: 14px !important;
    }

    .listing-list .listing-list-body .property-details .bottom-section {
        display: block !important;
        margin-top: 5px !important;
    }

    .listing-list .listing-list-body .property-details .bottom-section .other-details .details-box {
        margin-top: 5px !important;
        margin-left: 0px !important;
        margin-right: 25px !important;
    }

    .active-listings-page-2 .listing-list .listing-list-body .property-details .top-section h2 {
        font-size: 16px !important;
        margin-bottom: 10px !important;
    }

    .active-listings-page-2 .listing-list .listing-list-body .property-details .top-section .badge p {
        font-size: 12px !important;
    }

    .property-details .details-header {
        display: block !important;
    }

    .property-details {
        .details-header {
            display: block !important;

            .address {
                h5 {
                    font-size: 16px !important;
                    text-align: center;
                }

                p {
                    text-align: center;
                }
            }

            .features {
                margin-top: 10px;
                justify-content: center;

                .tile {
                    padding: 0px 10px !important;

                    .value {
                        white-space: nowrap !important;
                        font-size: 14px !important;
                    }

                    .label {
                        font-size: 12px !important;
                    }
                }

                .tile:first-child {
                    padding-left: 0px !important;
                    border-left: 0px solid transparent;
                }
            }
        }

        .page-left {
            .property-photos {
                .img-slider {
                    img {
                        height: 300px !important;
                    }
                }
            }

            .description .content-css {
                font-size: 14px !important;
            }

            .mortgage-calculator {
                form {
                    margin-top: 10px !important;
                }
            }

            .nearby-schools {
                overflow-x: auto;
                overflow-y: hidden;
            }
        }

        .page-right {
            margin-top: 15px;
        }
    }

    .home-evaluation .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .home-evaluation .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .home-evaluation .page-banner .heading-overlay h1 {
        font-size: 25px !important;
    }

    .home-evaluation .content {
        padding: 30px 0px !important;
    }

    .home-evaluation .content p {
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .home-evaluation .content .content-list li {
        font-size: 14px !important;
    }

    .home-evaluation .home-evaluation-form .form-box {
        padding: 20px !important;
    }

    .home-evaluation .home-evaluation-form .form-box h2 {
        font-size: 25px !important;
    }

    .home-evaluation .home-evaluation-form .form-box h5 {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .form-group .only-field {
        margin-top: 0px !important;
    }

    .home-evaluation .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .header .header-wrap .header-links .logo-box .agent-logo {
        width: 80px !important;
        height: auto;
    }

    .footer .copyright-main .links {
        justify-content: center !important;
    }

    .homepage .featured-listings .heading {
        display: block !important;
    }

    .homepage .featured-listings .heading .listings-link {
        margin-top: 10px;
    }

    .active-listings-page-2 .listing-list .listing-list-body .property-details .middle-section p {
        margin-top: 5px !important;
    }

    .logo-box {
        img {
            width: 150px;
            height: auto;
        }
    }

    .property-type .page-details .counters .border-right {
        border-right: 0px solid transparent;
    }

    .property-type .page-banner .overlay-text h1 {
        font-size: 40px;
    }

    .property-type .page-banner .overlay-text h2 {
        font-size: 18px;
        line-height: 28px;
    }

    .property-type .page-details {
        padding: 30px 0px;
    }

    .property-type .page-details .counters {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .property-type .page-details .counters .box p {
        font-size: 14px;
    }

    .property-type .page-details h2 {
        font-size: 30px;
    }

    .property-type .property-listing {
        padding: 30px 0px;
    }

    .property-type .property-listing h2 {
        font-size: 30px;
    }

    .homepage .about {
        padding: 30px 0px;
    }

    .homepage .counters-section .counter-box .border-right {
        border-right: 0px solid transparent;
    }

    .homepage .counters-section .counter-box .box .value {
        font-size: 40px;
    }

    .homepage .counters-section .counter-box .box p {
        font-size: 14px;
    }

    .header.dark .top-header .header-links .right-section {
        display: block;
    }
}

@media (max-width: 1200px) {
    .homepage .about img {
        margin-bottom: 20px;
        height: 400px;
    }

    .homepage .featured-listings .heading .listings-link {
        white-space: nowrap;
    }

    .homepage .featured-listings .property-box .property-box-body .details h5 {
        font-size: 15px;
    }

    .buyer-overview .page-content .content.with-image img {
        margin-top: 20px;
    }

    .footer .footer-main .agent-details {
        flex-wrap: wrap;

        .details-box:last-child {
            margin-top: 15px;
        }
    }

    .active-listings-page-2 .property-listings .price-filters .react-select.sort-control .custom-select__control {
        margin-top: 10px;
    }

    .active-listings-page-2 .property-listings .price-filters .filters-btn {
        margin-top: 10px;
        height: auto !important;
    }

    .contact-us .details iframe {
        margin-top: 10px;
        height: 500px;
    }

    .homepage .instagram-wall-amberwood .heading {
        display: block;
    }

    .homepage .instagram-wall-amberwood {
        margin: 0px 10px;
    }

    .header .top-header .header-links .right-section .social-media-box p {
        font-size: 13px;
    }

    .new-property-detail .details-header {
        display: block;
    }

    .new-property-detail .details-header .features {
        margin-top: 10px;
        display: block;
    }

    .new-property-detail .details-header .features .tile {
        padding: 5px 10px;
        border-left: 0px solid transparent;
    }

    .new-property-detail .page-left .property-photos .img-slider img {
        height: 400px;
    }

    .nav-link {
        text-transform: uppercase;
        font-weight: 600;
    }

    .contact-us .details {
        padding: 15px 0px;
    }
}
